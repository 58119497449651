import type {
  ContentTypeCommunityStoryblok,
  PageSectionStoryblok,
} from '@/types/storyblok'

import type { NavigationDesktopMegaMenuTheme } from '@/components/navigation/types'
import type { NavigationMenuItemCustomColorTheme } from '@/storyblok/block/navigation/types'
import type { PageSectionCustomTheme } from '@/storyblok/page/types'
import type { UiButton } from '@/components/ui/types'

export function isLightTheme(theme: PageSectionStoryblok['theme']): boolean {
  return theme === 'light' || theme === 'muted'
}

export function isDarkTheme(theme: PageSectionStoryblok['theme']): boolean {
  return theme === 'dark'
}

export function getCommunityThemeButtonCSSVars(
  buttonVariant: UiButton.Variant,
  theme: PageSectionStoryblok['theme'],
  { contentLight, backgroundDark }: ContentTypeCommunityStoryblok,
): UiButton.CustomTheme | undefined {
  const isLightTheme =
    theme === 'muted' || theme === 'white' || theme === 'light'

  switch (buttonVariant) {
    case 'outline':
      if (isLightTheme) {
        return {
          '--custom-theme-border-color': contentLight.color,
          '--custom-theme-text-color': contentLight.color,
          '--custom-theme-hover-fill-color': contentLight.color,
          '--custom-theme-hover-text-color': backgroundDark.color,
        }
      } else {
        return {
          '--custom-theme-border-color': backgroundDark.color,
          '--custom-theme-text-color': backgroundDark.color,
          '--custom-theme-hover-fill-color': backgroundDark.color,
          '--custom-theme-hover-text-color': contentLight.color,
        }
      }
    case 'fill':
      if (isLightTheme) {
        return {
          '--custom-theme-fill-color': contentLight.color,
          '--custom-theme-text-color': backgroundDark.color,
          '--custom-theme-hover-fill-color': contentLight.color,
          '--custom-theme-hover-text-color': backgroundDark.color,
        }
      } else {
        return {
          '--custom-theme-fill-color': backgroundDark.color,
          '--custom-theme-text-color': contentLight.color,
          '--custom-theme-hover-fill-color': backgroundDark.color,
          '--custom-theme-hover-text-color': contentLight.color,
        }
      }
  }
}

export function getCommunityThemeMenuLinkCSSVars({
  contentLight,
}: ContentTypeCommunityStoryblok): NavigationMenuItemCustomColorTheme {
  return {
    '--custom-theme-hover-text-color': contentLight.color,
  }
}

export function getCommunityThemeMegaMenuCSSVars({
  backgroundDark,
  contentDark,
  contentLight,
}: ContentTypeCommunityStoryblok): NavigationDesktopMegaMenuTheme {
  return {
    '--custom-theme-fill-color': backgroundDark.color,
    '--custom-theme-text-color': contentDark.color,
    '--custom-theme-title-color': contentLight.color,
    '--custom-theme-hover-text-color': contentLight.color,
    '--custom-theme-border-color': isDarkColor(backgroundDark.color)
      ? 'rgba(255, 255, 255, 0.1)'
      : undefined,
  }
}

export function getCommunityPageSectionThemeCSSVars(
  pageSectionTheme: PageSectionStoryblok['theme'],
  {
    backgroundDark,
    backgroundLight,
    contentDark,
    contentLight,
  }: ContentTypeCommunityStoryblok,
): PageSectionCustomTheme | undefined {
  return pageSectionTheme === 'light'
    ? {
        '--custom-theme-fill-color': backgroundLight.color,
        '--custom-theme-text-color': contentLight.color,
      }
    : pageSectionTheme === 'dark'
      ? {
          '--custom-theme-fill-color': backgroundDark.color,
          '--custom-theme-text-color': contentDark.color,
        }
      : undefined
}

export function isDarkColor(color: string): boolean {
  const hex = color.replace('#', '')

  // Validate hex color code
  if (!/^([0-9A-Fa-f]{3}){1,2}$/.test(hex)) {
    throw new Error('Invalid hex color code')
  }

  // Expand shorthand hex to full form
  const fullHex =
    hex.length === 3
      ? hex
          .split('')
          .map((char) => char + char)
          .join('')
      : hex

  // Extract RGB values
  const [r, g, b] = [0, 2, 4].map((offset) =>
    parseInt(fullHex.substring(offset, offset + 2), 16),
  )

  // Calculate luminance
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b

  return luma < 128
}
